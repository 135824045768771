<template>
  <aula-modal
    header-text="EDITOR_SECURE_FILES_MODAL_HEADER"
    css-class="modal-medium"
    ok-text="CALENDAR_BUTTON_SAVE"
    always-visible
    @closeClicked="onCanceled"
    @cancelClicked="onCanceled"
    @okClicked="onSelectDocuments"
  >
    <div class="documents-secure-list-container">
      <div class="aula-documentsSecureList-container scrollbar">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex justify-content-start">
            <aula-select
              v-if="filterPerson.length > 0"
              v-model="selectedFilterPerson"
              filterable
              class="document-picker-filter mb-1 mr-2"
              @change="updateFilter()"
            >
              <aula-option
                v-for="filter in filterPerson"
                :key="filter.value"
                :label="filter.text"
                :value="filter.value"
              />
            </aula-select>
            <aula-select
              v-if="filterCategory.length > 0"
              v-model="selectedFilterCategory"
              filterable
              class="document-picker-filter mb-1"
              @change="updateFilter()"
            >
              <aula-option v-for="(filter, i) in filterCategory" :key="i" :label="filter.text" :value="filter.value" />
            </aula-select>
          </div>
          <div class="sort d-flex flex-grow-1 justify-content-end">
            <b-dropdown class="dropdown-select" right>
              <template slot="button-content">
                <span class="sort ml-n1" :class="sortDirection" />
                {{ ('DOCUMENTS_SHARED_OVERVIEW_TABLE_' + sort.toUpperCase()) | fromTextKey }}
                <i class="icon icon-Aula_down-arrow" />
              </template>
              <b-dropdown-item :class="sort == 'title' ? sortDirection : ''" @click="setSortOrder('title')">
                {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE' | fromTextKey }}
              </b-dropdown-item>
              <b-dropdown-item :class="sort == 'updatedAt' ? sortDirection : ''" @click="setSortOrder('updatedAt')">
                {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED' | fromTextKey }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="clearfix" />
        <div class="mt-3 mb-2 font-weight-bold">
          {{ 'DOCUMENTS_SHARED_OVERVIEW_DESCRIPTION_TEXT' | fromTextKey }}
        </div>
        <ObservingContainer :options="observerOptions">
          <div class="table">
            <div class="table-row header">
              <div class="table-cell" />
              <div class="table-cell title">
                {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE' | fromTextKey }}
              </div>
              <div class="table-cell">
                {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_DESCRIPTION' | fromTextKey }}
              </div>
              <div class="table-cell">
                {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_ASSOCIATION' | fromTextKey }}
              </div>
              <div class="table-cell">
                {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED' | fromTextKey }}
              </div>
            </div>
            <template v-for="(item, i) in documents">
              <label
                v-if="
                  !onlyWritePermisson ||
                  (onlyWritePermisson && item.canEdit && item.documentType != documentTypes.EXTERNAL)
                "
                :key="i"
                class="table-row body"
                :class="{ disabled: !item.isShareable }"
              >
                <div class="table-cell pl-2 pr-0 pl-lg-3 pr-lg-2">
                  <b-checkbox
                    v-model="selectedDocuments"
                    class="document-select-checkbox"
                    :value="item"
                    :disabled="!item.isShareable"
                  />
                </div>
                <div class="table-cell title not-collapsed">
                  <div v-if="item.documentType == documentTypes.EXTERNAL" class="filetype">Importeret fil</div>
                  <b>{{ item.title }}</b>
                  ({{ item.category }})
                </div>
                <div class="table-cell not-collapsed">
                  {{ getDocumentDescription(item) }}
                </div>
                <div class="table-cell not-collapsed">
                  <span v-for="(association, index) in item.associatedInstitutionProfiles" :key="index"
                    ><span v-if="index > 0">,</span> {{ association | displayProfileNameWithMetadata }}</span
                  >
                </div>
                <div class="table-cell not-collapsed">
                  <nobr>{{ item.updatedAt | shortDate(true) }}</nobr>
                  <nobr>kl {{ item.updatedAt | time }}</nobr>
                </div>
                <div class="table-cell collapsed-only">
                  <div class="item">
                    <div v-if="item.documentType == documentTypes.EXTERNAL" class="filetype">Importeret fil</div>
                    <b>{{ item.title }}</b>
                    ({{ item.category }})
                  </div>
                  <div v-if="getDocumentDescription(item)" class="item">
                    <strong>{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_DESCRIPTION' | fromTextKey }}:</strong>
                    {{ getDocumentDescription(item) }}
                  </div>
                  <div class="item">
                    <strong>{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED' | fromTextKey }}:</strong>
                    <nobr>{{ item.updatedAt | shortDate(true) }}</nobr>
                    <nobr>kl {{ item.updatedAt | time }}</nobr>
                  </div>
                  <div class="item">
                    <strong>{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_ASSOCIATION' | fromTextKey }}:</strong>
                    <span v-for="(association, index) in item.associatedInstitutionProfiles" :key="index"
                      ><span v-if="index > 0">,</span> {{ association | displayProfileNameWithMetadata }}</span
                    >
                  </div>
                </div>
              </label>
            </template>
          </div>
          <div v-if="isLoading" class="spinHolder">
            <aula-spinner />
          </div>
          <ObservedTarget v-if="documents.length > 0" @onIntersecting="onBottomReached" />
        </ObservingContainer>
        <div v-if="documents.length === 0 && !isLoading" class="no-documents">
          {{ 'DOCUMENTS_SECURE_NO_DOCUMENTS' | fromTextKey }}
        </div>
      </div>
    </div>
  </aula-modal>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapActions } from 'vuex';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { docTypes } from '../../../shared/enums/docTypes';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { documentCategories } from '../../../shared/enums/documentCategories';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { documentTypes } from '../../../shared/enums/documentTypes';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';
import truncate from 'lodash/truncate';

const MAX_SECURE_NOTE_DESCRIPTION_LENGTH = 300;
const UNIQUE_THREAD_ROUTE_NAME = 'uniqueThread';

export default {
  components: { ObservedTarget, ObservingContainer },
  props: {
    parent: { type: String, default: parentTypes.DOCUMENTS },
    onlyWritePermisson: { type: Boolean, default: false },
    canSelectAllDocuments: { type: Boolean, default: false },
  },
  data: function () {
    return {
      attachments: [],
      isLoading: true,
      choosenGroups: [],
      appendedGroup: [],
      appendedPerson: [],
      moduleTypes: moduleTypes,
      permissionEnum: permissionEnum,
      portalRoles: portalRoles,
      categoriesList: documentCategories,
      documentHtml: '',
      association: '',
      choosenGroup: [],
      docTypes: docTypes,
      parentTypes: parentTypes,
      documentTypes: documentTypes,
      category: '',
      path: '',
      associationFilter: '',
      selectedCategory: 'OPENTEMPLATE',
      paginationStart: 0,
      pageSize: 20,
      sort: 'updatedAt',
      sortDirection: 'desc',
      showUploadTypePopup: false,
      fileToUpload: null,
      currentShared: [],
      editDrawer: false,
      appendSharedUsersAndGroupsSet: [],
      isCreating: false,
      showMissingAssociation: false,
      selectedFilterGroup: [],
      filterPerson: [],
      filterCategory: [],
      workingDocument: {},
      selectedFilterPerson: null,
      selectedFilterCategory: null,
      status: [],
      selectedDocuments: [],
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      selectedItem: types.GET_SELECTED_ITEM,
      shareableDocumentItems: types.GET_SHAREABLE_SECURE_DOCUMENTS,
      liveDocument: types.GET_DOCUMENTS_LIVE,
      hasPermission: types.HAS_PERMISSION,
      isDirty: types.GET_DOCUMENTS_IS_DIRTY,
      selectedRecipients: types.MESSAGES_GET_SELECTED_RECIPIENTS,
    }),
    observerOptions() {
      return { rootMargin: '50%' };
    },
    documents() {
      return this.shareableDocumentItems?.documents || [];
    },
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    ...mapActions({
      loadShareableDocuments: types.ACTION_GET_SHAREABLE_DOCUMENTS,
      appendDocuments: types.ACTION_GET_MORE_SHAREABLE_DOCUMENTS,
      setSelectedItem: types.ACTIONS_DOCUMENTS_SET_SELECTED_ITEM,
      editSharings: types.ACTION_EDIT_SHARINGS,
      editSetupDocument: types.ACTION_LOAD_INTERNAL_DOCUMENT,
      discardDocument: types.ACTION_DISCARD_DOCUMENT,
      setDocument: types.ACTION_DOCUMENTS_SET_LIVE,
    }),
    getDocumentDescription(doc) {
      switch (doc.documentType) {
        case documentTypes.NOTE:
          return truncate(doc.description ?? '', { length: MAX_SECURE_NOTE_DESCRIPTION_LENGTH });
        case documentTypes.RICHDOCUMENT:
          return doc.templateTitle ?? '';
        default:
          return '';
      }
    },
    onBottomReached() {
      if (this.isLoading || !this.shareableDocumentItems.moreDocumentsExists) {
        return;
      }
      this.getMoreDocuments();
    },
    onCanceled() {
      this.$emit('onCanceled');
    },
    updateFilter() {
      this.paginationStart = 0;
      this.selectedDocuments = [];
      this.$nextTick(() => {
        this.getDocuments();
      });
    },
    setSortOrder(sortItem) {
      this.selectedDocuments = [];
      if (this.sort == sortItem) {
        this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
      } else {
        this.sort = sortItem;
        if (sortItem == 'updatedAt') {
          this.sortDirection = 'desc';
        } else {
          this.sortDirection = 'asc';
        }
      }
      this.getDocuments();
    },
    async getDocuments() {
      if (this.paginationStart == 0) {
        this.status = [];
      }
      const index = this.paginationStart * this.pageSize;
      const limit = this.pageSize;
      const sortings = [
        {
          order: this.sortDirection,
          field: this.sort,
        },
        {
          order: this.sortDirection,
          field: 'id',
        },
      ];
      const filterDocumentCategories = [];
      const filterRegardingStudentIds = [];
      let filterRegardingGroupIds = [];
      let shareToInstitutionProfileIds = [];

      if (!this.canSelectAllDocuments) {
        shareToInstitutionProfileIds = this.getInstitutionProfileRecipientIds();
      }
      if (this.selectedFilterGroup) {
        filterRegardingGroupIds = this.selectedFilterGroup;
      }
      if (this.selectedFilterPerson) {
        filterRegardingStudentIds.push(this.selectedFilterPerson);
      }
      if (this.selectedFilterCategory) {
        filterDocumentCategories.push(this.selectedFilterCategory);
      }
      const payload = {
        index,
        limit,
        sortings,
        filterRegardingStudentIds,
        filterDocumentCategories,
        filterRegardingGroupIds,
        shareToInstitutionProfileIds,
      };
      this.isLoading = true;
      if (this.paginationStart == 0) {
        await this.loadShareableDocuments(payload);
      } else {
        await this.appendDocuments(payload);
      }
      this.isLoading = false;
      this.setDocumentFilters();
    },
    setDocumentFilters() {
      this.filterPerson = [];
      this.filterCategory = [];
      if (this.shareableDocumentItems.filters.regardingInstitutionProfiles.length > 0) {
        this.filterPerson.push({ value: null, text: 'Filtrer på barn' });
      }
      if (this.shareableDocumentItems.filters.documentCategories.length > 0) {
        this.filterCategory.push({ value: null, text: 'Filtrer på kategori' });
      }
      for (const item of this.shareableDocumentItems.filters.regardingInstitutionProfiles) {
        this.filterPerson.push({ value: item.id, text: item.name });
      }
      for (const item of this.shareableDocumentItems.filters.documentCategories) {
        this.filterCategory.push({ value: item, text: item });
      }
    },
    getMoreDocuments() {
      this.paginationStart++;
      this.getDocuments();
    },
    getCategoryValue(cat) {
      const catArray = [cat];
      const liste = this.categoriesList;
      const filteredObject = Object.keys(liste).reduce(function (r, e) {
        if (catArray.includes(liste[e])) {
          r = e;
        }
        return r;
      }, {});
      return filteredObject;
    },
    onSelectDocuments() {
      const results = this.selectedDocuments.map(document => ({
        attachedSecureDocumentId: document.id,
        type: document.documentType,
        title: document.title,
        name: document.title,
        shareableGuardianIds: document.shareableGuardianIds,
      }));
      this.$emit('onSelectDocuments', results);
    },
    getInstitutionProfileRecipientIds() {
      let recipientIds = [];
      const { name, params } = this.$route;
      const isUniqueThread = name === UNIQUE_THREAD_ROUTE_NAME;
      const isDraft = params.id != null && params.id.toString().match(/^DRAFT/);
      if (!isUniqueThread || isDraft) {
        recipientIds = this.selectedRecipients
          .filter(recipient => recipient.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE)
          .map(recipient => recipient.id);
      } else {
        recipientIds = this.selectedRecipients
          .filter(
            recipient =>
              recipient.leaveTime === null &&
              recipient.mailBoxOwner?.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE
          )
          .map(recipient => recipient.mailBoxOwner.id);
      }
      return recipientIds.filter(id => id != null);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.documents-secure-list-container {
  height: 100%;
  .aula-documentsSecureList-container {
    padding: 19px;
    background-color: $color-grey-light;
    height: 100%;
    overflow-y: auto;

    .document-picker-filter {
      --modal-inner-input-background-color: var(--color-white);
    }
  }
}

.table {
  position: relative;
  z-index: 100;
  label.table-row.body {
    text-transform: unset;
    font-weight: unset;
    &.disabled {
      background-color: var(--color-grey-darker);
    }
  }
}

.header > .table-cell {
  &.title {
    min-width: 120px;
  }
}

.table-cell.check {
  width: 20px;
  padding: 0 !important;
  .custom-checkbox {
    padding: 4px;
    margin-bottom: 0;
    left: 30px;
  }
}
.table-cell.actions {
  .custom-checkbox {
    margin-bottom: 20px;
    left: 8px;
  }
}

.upload-link {
  cursor: pointer;
  float: left;
  margin-top: 12px;
}
.submit-row {
  margin-top: 15px;
}
.icon-Aula_edit_pen {
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
}
.icon-Aula_attach_doc {
  font-size: 20px;
  margin-right: 15px;
  margin-top: 5px;
  display: block;
}
.modalSharedWith {
  .row {
    .col,
    .col-1,
    .col-2,
    .col-4 {
      padding: 10px 10px 5px 10px;
      border-bottom: solid 1px #eee;
    }
    .text {
      padding-top: 13px;
    }
    .check {
      text-align: center;
      label {
        margin-right: 0;
      }
    }
    .delete {
      text-align: right;
      padding-top: 14px;
    }
  }
}
.file-badge {
  position: absolute;
  left: -6px;
  top: -8px;
}
.drawer-content .aula-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 40px;
  top: -12px;
}
.spinHolder {
  clear: both;
}
.show-more {
  padding: 20px 0;
  text-align: center;
  button {
    float: none;
  }
}
.filetype {
  color: $color-primary-base-guardian;
  .theme-employee & {
    color: $color-primary-base-employee;
  }
}

.no-documents {
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
}

.sort {
  &.asc::before,
  &.desc::before {
    margin-left: 3px !important;
    margin-right: -3px !important;
    color: unset !important;
  }
}
</style>
